import { Menu } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import clsx from "clsx";
import React, { FC, useMemo } from "react";
import { UserNav } from "../components/UserNav";
import { useUserContext } from "../context/UserContext";
import { getUserMonogram } from "../utils/users";
import { Tooltip } from "./Tooltip";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      zIndex: theme.zIndex.appBar,
    },
    smallButton: {
      padding: 3,
      paddingRight: 8,
      border: "1px solid #dedede",
    },
    currentUser: {
      width: "100%",
    },
    label: {
      ...theme.typography.body2,
      display: "flex",
      flexDirection: "column",
      fontWeight: theme.typography.fontWeightMedium,
      alignItems: "flex-start",
      "&.light": {
        color: theme.palette.common.white,
      },
      "&.dark": {
        color: theme.palette.text.primary,
      },
    },
    username: {
      color: theme.palette.common.white,
      marginLeft: -4,
      whiteSpace: "nowrap",
      "&.light": {
        color: theme.palette.common.white,
      },
      "&.dark": {
        color: theme.palette.text.primary,
      },
    },
    avatar: {
      height: 24,
      width: 24,
      "&$rightMargin": {
        marginRight: theme.spacing(1),
      },
      "&$largeAvatar": {
        height: 32,
        width: 32,
      },
    },
    smallUserName: {
      fontSize: 14,
    },
    smallAvatar: {
      height: 24,
      width: 24,
    },
    largeAvatar: {},
    rightMargin: {},
    downChevron: {
      marginLeft: -4,
      "&.light": {
        color: theme.palette.common.white,
      },
      "&.dark": {
        color: theme.palette.text.primary,
      },
    },
    menu: {
      margin: theme.spacing(5, 0, 1, 0),
    },
    menuPaper: {
      minWidth: "13em",
      color: theme.palette.getContrastText("#363740"),
      backgroundColor: "#363740",
      borderRadius: theme.shape.borderRadius * 1.5,
    },
  }),
  {
    classNamePrefix: "CurrentUser",
  }
);

type CurrentUserProps = {
  className?: string;
  color?: string;
  withUserName?: boolean;
  size?: "medium" | "large" | "small";
  isOnboarding?: boolean;
};

export const CurrentUser: FC<CurrentUserProps> = ({
  className,
  color = "light",
  isOnboarding = false,
  withUserName,
  size,
}) => {
  const classes = useStyles();

  const [{ user, isAuthenticated }] = useUserContext();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const monogram = useMemo(() => getUserMonogram(user), [user]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      {!!user && (
        <Box className={classes.currentUser}>
          <Tooltip title={user.email || ""} placement="bottom">
            <Button
              ref={anchorRef}
              variant="text"
              size="large"
              className={clsx({
                [classes.smallButton]: size === "small",
              })}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              startIcon={
                <Badge badgeContent={0} color="secondary">
                  <Avatar
                    className={clsx(classes.avatar, {
                      [classes.rightMargin]: withUserName,
                      [classes.largeAvatar]: size === "large",
                      [classes.smallAvatar]: size === "small",
                    })}
                    src={user.avatarUrl}
                    alt={user.email}
                    title={user.email}
                  >
                    {monogram}
                  </Avatar>
                </Badge>
              }
              endIcon={<KeyboardArrowDownRoundedIcon className={clsx(classes.downChevron, color)} />}
            >
              {withUserName ? (
                <Box className={classes.label}>
                  <Typography
                    variant="body1"
                    className={clsx(classes.username, { [classes.smallUserName]: size === "small" }, color)}
                  >
                    {user.name}
                  </Typography>
                </Box>
              ) : (
                <Typography className={clsx(classes.label, color)}>Account</Typography>
              )}
            </Button>
          </Tooltip>
          <Menu
            className={classes.menu}
            open={open}
            classes={{ paper: classes.menuPaper }}
            anchorEl={anchorRef.current}
            role={undefined}
            onClose={handleClose}
          >
            {isAuthenticated && (
              <UserNav isCollapsible={false} showAvatar={false} isOnboarding={isOnboarding} onMenuClose={handleClose} />
            )}
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default CurrentUser;
